import React from "react";
import Layout from "../components/Layout";
import Newsletter from "../components/Newsletter";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { PresentationChartBarIcon, PauseIcon } from "@heroicons/react/solid";
import {
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
} from "@heroicons/react/outline";
import { CheckIcon, ThumbUpIcon, UserIcon } from "@heroicons/react/solid";
import SEO from "../components/SEO";

const timeline = [
  {
    id: 1,
    content: "Workshop Start",
    date: "9:30am",
    datetime: "2020-09-20",
    icon: PresentationChartBarIcon,
    iconBackground: "bg-e-yellow",
  },
  {
    id: 2,
    content: "Lunch",
    date: "1:00pm",
    datetime: "2020-09-22",
    icon: PauseIcon,
    iconBackground: "bg-e-yellow",
  },
  {
    id: 3,
    content: "Workshop finish",
    date: "4:30pm",
    datetime: "2020-09-28",
    icon: CheckIcon,
    iconBackground: "bg-e-yellow",
  },
  {
    id: 4,
    content: "Morning and afternoon tea provided. The times will be fluid.",
    date: "Note:",
    datetime: "2020-09-30",
    icon: ThumbUpIcon,
    iconBackground: "bg-e-yellow",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const features = [
  {
    name: "Competitive exchange rates",
    description:
      "Appropriately receive, respond to and manage sexual harassment complaints",
    icon: GlobeAltIcon,
  },
  {
    name: "No hidden fees",
    description:
      "Identify and implement the causes of the infringing behaviour and most effective response action for better outcomes",
    icon: ScaleIcon,
  },
  {
    name: "Transfers are instant",
    description:
      "Create tailored investigation templates for your business to apply into the future for similar matters and/or train others in your team",
    icon: LightningBoltIcon,
  },
  {
    name: "Transfers are instant",
    description:
      "Learn and apply the most effective processes for investigating sexual harassment matters",
    icon: LightningBoltIcon,
  },
  {
    name: "Transfers are instant",
    description:
      "Develop and hone existing skills through practising a hypothetical case study and solving common investigation issues",
    icon: LightningBoltIcon,
  },
];

const WorkplaceInvestigations = () => {
  return (
    <Layout>
      <SEO
        title="Workplace Investigations Workshop"
        slug="workplace-investigations-workshop"
      />
      {/* HERO */}
      <div className="mt-12 pb-12 block relative bg-e-blue overflow-hidden lg:h-[750px]">
        <div className="absolute inset-0">
          <StaticImage
            className="w-full object-cover h-full opacity-80 lg:opacity-100"
            src="https://images.ctfassets.net/agskyiykp2ye/25CqlcPZhh8hCfR36xfWWM/ead29d39a244b9ec822d77702f9a0f20/jeremy-mcgilvrey-CnAgA4rmGUQ-unsplash.jpg"
            alt=""
          />
          {/* <div
            className="absolute inset-0 bg-e-blue mix-blend-multiply"
            aria-hidden="true"
          /> */}
          <svg
            width="925"
            height="750"
            viewBox="0 0 925 750"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-0 top-0 hidden lg:block"
          >
            <path
              d="M344.5 0.327271L0 750.327H925V0.327271H344.5Z"
              fill="#1D71B8"
              fillOpacity="0.4"
            />
          </svg>
        </div>
        <div className="relative max-w-7xl mx-auto pt-24 px-4 block lg:flex lg:flex-row  items-end">
          <div className="pb-6 pt-4">
            <h2 className="text-4xl uppercase font-bold text-white ">
              Sexual Harassment Workplace Investigations
            </h2>
            <p className="uppercase text-3xl text-white font-medium">
              Workshop
            </p>
          </div>

          <div className="bg-e-blue text-white py-8 px-4 border-t-8 border-e-yellow shadow sm:px-10">
            <div className="space-y-6" action="#" method="POST">
              <h2 className="text-3xl uppercase font-medium pb-6 border-b border-e-yellow">
                Event Details
              </h2>
              <div>
                <label
                  htmlFor="email"
                  className="block text-md font-medium text-white uppercase"
                >
                  Date:
                </label>
                <div className="text-lg text-e-yellow font-semibold pb-6 border-b border-e-yellow">
                  <p>Friday 24th June, 2022</p>
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-md font-medium text-white uppercase"
                >
                  Location:
                </label>
                <div className="text-lg text-e-yellow font-semibold pb-6 border-b border-e-yellow">
                  <p>Wrest Point - Boardwalk Gallery</p>
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-md font-medium text-white uppercase"
                >
                  Cost:
                </label>
                <div className="text-lg text-e-yellow font-semibold pb-6 border-b border-e-yellow">
                  <p>$900 plus GST</p>
                  <ul className="pl-6">
                  <li className="list-disc">
                    <p className="font-medium text-md leading-5 w-64">
                      Our retainer clients can virtually attend for FREE or
                      attend in person for $450 plus GST{" "}
                    </p>
                  </li>
                  </ul>

                </div>
              </div>

              <div>
                <a
                  href="https://www.eventbrite.com.au/e/workplace-sexual-harassment-investigations-training-tickets-348407394897"
                  target="blank"
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-black bg-e-yellow hover:bg-e-yellow-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Purchase Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* OBJECTIVES */}
      <div className="py-12 bg-e-blue bg-opacity-60">
        <p className="max-w-7xl text-center  mx-auto mt-2 pb-12 px-4 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
          Workshop Objectives
        </p>
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-5 lg:gap-8">
            <div>
              <dt>
                <div className="flex mx-auto items-center justify-center h-24 w-24 rounded-full  shadow-lg shadow-e-yellow border-white border-4 bg-e-blue text-white">
                  <svg
                    width="66"
                    height="67"
                    viewBox="0 0 66 67"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M60.5 20.9559V37.4559C60.5 41.5809 59.125 45.0184 56.705 47.4384C54.3125 49.8309 50.875 51.2059 46.75 51.2059V57.0634C46.75 59.2634 44.3025 60.5835 42.4875 59.3735L30.25 51.2059H24.42C24.64 50.3809 24.75 49.5285 24.75 48.6485C24.75 45.8435 23.6775 43.2585 21.9175 41.306C19.9375 39.051 16.995 37.6485 13.75 37.6485C10.67 37.6485 7.86501 38.9135 5.85751 40.976C5.61001 39.876 5.5 38.6934 5.5 37.4559V20.9559C5.5 12.7059 11 7.20593 19.25 7.20593H46.75C55 7.20593 60.5 12.7059 60.5 20.9559Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24.75 48.6484C24.75 50.7109 24.1725 52.6634 23.155 54.3134C22.5775 55.3034 21.835 56.1834 20.9825 56.8984C19.0575 58.6309 16.5275 59.6484 13.75 59.6484C9.735 59.6484 6.24246 57.5034 4.34496 54.3134C3.32746 52.6634 2.75 50.7109 2.75 48.6484C2.75 45.1834 4.345 42.076 6.875 40.0685C8.7725 38.556 11.165 37.6484 13.75 37.6484C19.8275 37.6484 24.75 42.5709 24.75 48.6484Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.45996 48.6484L12.1824 51.3709L18.0399 45.9535"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M26 29.5234H39"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M23.375 20.5234H42.625"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="divider border-t-4 border-e-yellow border-dotted my-8 mx-12"></div>
              </dt>
              <dd className="mt-2 text-base text-white text-center lg:text-left">
                Appropriately receive, respond to and manage sexual harassment
                complaints
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex mx-auto items-center justify-center h-24 w-24 rounded-full  shadow-lg shadow-e-yellow border-white border-4 bg-e-blue text-white">
                  <svg
                    width="64"
                    height="65"
                    viewBox="0 0 64 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M54.6668 30.6567V19.2968C54.6668 8.55014 52.1602 5.85678 42.0802 5.85678H21.9202C11.8402 5.85678 9.3335 8.55014 9.3335 19.2968V49.3234C9.3335 56.4168 13.2269 58.0968 17.9469 53.0301L17.9735 53.0034C20.1601 50.6834 23.4935 50.8701 25.3868 53.4034L28.0802 57.0034"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M48.5334 57.5901C53.2462 57.5901 57.0667 53.7696 57.0667 49.0568C57.0667 44.344 53.2462 40.5234 48.5334 40.5234C43.8205 40.5234 40 44.344 40 49.0568C40 53.7696 43.8205 57.5901 48.5334 57.5901Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M58.6667 59.1901L56 56.5234"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21.3335 19.1901H42.6668"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24 29.8568H40"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="divider border-t-4 border-e-yellow border-dotted my-8 mx-12"></div>
              </dt>
              <dd className="mt-2 text-base text-white text-center lg:text-left">
                Identify and implement the causes of the infringing behaviour
                and most effective response action for better outcomes
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex mx-auto items-center justify-center h-24 w-24 rounded-full  shadow-lg shadow-e-yellow border-white border-4 bg-e-blue text-white">
                  <svg
                    width="67"
                    height="68"
                    viewBox="0 0 67 68"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.7085 54.9609H58.6252"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M30.7085 35.4193H58.6252"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M30.7085 15.8776H58.6252"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.375 15.8776L11.1667 18.6693L19.5417 10.2943"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.375 35.4193L11.1667 38.2109L19.5417 29.8359"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.375 54.9609L11.1667 57.7526L19.5417 49.3776"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="divider border-t-4 border-e-yellow border-dotted my-8 mx-12"></div>
              </dt>
              <dd className="mt-2 text-base text-white text-center lg:text-left">
                Learn and apply the most effective processes for investigating
                sexual harassment matters
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex mx-auto items-center justify-center h-24 w-24 rounded-full  shadow-lg shadow-e-yellow border-white border-4 bg-e-blue text-white">
                  <svg
                    width="62"
                    height="67"
                    viewBox="0 0 62 67"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M56.0733 28.0234V41.7734C56.0733 55.5234 50.9757 61.0234 38.2318 61.0234H22.9391C10.1952 61.0234 5.09766 55.5234 5.09766 41.7734V25.2734C5.09766 11.5234 10.1952 6.02344 22.9391 6.02344H35.683"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M56.0733 28.0234H45.8782C38.2319 28.0234 35.6831 25.2734 35.6831 17.0234V6.02344L56.0733 28.0234Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M42.6585 34.5174H35.4146"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M23.3415 34.5174H18.5122"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M28.1709 38.743C30.5047 38.743 32.3965 36.8511 32.3965 34.5174C32.3965 32.1836 30.5047 30.2917 28.1709 30.2917C25.8372 30.2917 23.9453 32.1836 23.9453 34.5174C23.9453 36.8511 25.8372 38.743 28.1709 38.743Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M42.6589 47.7979H37.8296"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M25.7561 47.7979H18.5122"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M33 52.0235C35.3338 52.0235 37.2256 50.1316 37.2256 47.7978C37.2256 45.4641 35.3338 43.5722 33 43.5722C30.6663 43.5722 28.7744 45.4641 28.7744 47.7978C28.7744 50.1316 30.6663 52.0235 33 52.0235Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="divider border-t-4 border-e-yellow border-dotted my-8 mx-12"></div>
              </dt>
              <dd className="mt-2 text-base text-white text-center lg:text-left">
                Create tailored investigation templates for your business to
                apply into the future for similar matters and/or train others in
                your team
              </dd>
            </div>
            <div>
              <dt>
                <div className="flex mx-auto items-center justify-center h-24 w-24 rounded-full  shadow-lg shadow-e-yellow border-white border-4 bg-e-blue text-white">
                  <svg
                    width="71"
                    height="72"
                    viewBox="0 0 71 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.791 36.0234L31.9206 43.1826L46.2093 28.8643"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M31.8023 7.77134C33.8435 6.02593 37.1864 6.02593 39.2573 7.77134L43.9314 11.7947C44.8189 12.5638 46.4756 13.1851 47.6589 13.1851H52.6881C55.8239 13.1851 58.3977 15.7588 58.3977 18.8947V23.9238C58.3977 25.0776 59.0189 26.7638 59.7881 27.6513L63.8114 32.3255C65.5568 34.3668 65.5568 37.7097 63.8114 39.7805L59.7881 44.4547C59.0189 45.3422 58.3977 46.9988 58.3977 48.1822V53.2113C58.3977 56.3472 55.8239 58.9209 52.6881 58.9209H47.6589C46.5052 58.9209 44.8189 59.5422 43.9314 60.3113L39.2573 64.3347C37.216 66.0801 33.8731 66.0801 31.8023 64.3347L27.1281 60.3113C26.2406 59.5422 24.5839 58.9209 23.4006 58.9209H18.2827C15.1468 58.9209 12.5731 56.3472 12.5731 53.2113V48.1526C12.5731 46.9988 11.9518 45.3422 11.2123 44.4547L7.21852 39.7509C5.50268 37.7097 5.50268 34.3963 7.21852 32.3551L11.2123 27.6513C11.9518 26.7638 12.5731 25.1072 12.5731 23.9534V18.8651C12.5731 15.7293 15.1468 13.1555 18.2827 13.1555H23.4006C24.5543 13.1555 26.2406 12.5343 27.1281 11.7651L31.8023 7.77134Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="divider border-t-4 border-e-yellow border-dotted my-8 mx-12"></div>
              </dt>
              <dd className="mt-2 text-base text-white text-center lg:text-left">
                Develop and hone existing skills through practising a
                hypothetical case study and solving common investigation issues
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {/* AGENDA */}
      <div className="bg-e-blue">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-12 lg:px-8 flex flex-col lg:flex-row items-center gap-12 lg:gap-48 ">
          <div className="">
            <h2 className="text-5xl font-extrabold text-white">
              Event Duration
            </h2>
          </div>
          <div className="flow-root">
            <ul role="list" className="-mb-8">
              {timeline.map((event, eventIdx) => (
                <li key={event.id}>
                  <div className="relative pb-8">
                    {eventIdx !== timeline.length - 1 ? (
                      <span
                        className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-e-yellow"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex space-x-3">
                      <div>
                        <span
                          className={classNames(
                            event.iconBackground,
                            "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-e-blue hover:bg-e-yellow-hover"
                          )}
                        >
                          <event.icon
                            className="h-5 w-5 text-e-blue "
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1 pt-1.5 flex space-x-4">
                        <div className="text-right text-md font-bold whitespace-nowrap text-e-yellow">
                          <time dateTime={event.datetime}>{event.date}</time>
                        </div>
                        <div>
                          <p className=" text-md text-e-yellow">
                            {event.content}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* CASE STUDY */}
      <div className="pt-8 bg-gray-50 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-none">
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-e-blue uppercase sm:text-4xl">
              Workshop Agenda
            </p>
          </div>
          <div className="relative z-10 text-base text-gray-500 max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72 py-12">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between px-4 py-2 text-left text-lg font-bold uppercase text-e-blue focus:outline-none focus-visible:ring focus-visible:ring-e-blue focus-visible:ring-opacity-75 border-b border-gray-300 ">
                    <span>Hypothetical Case Study </span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-e-blue`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-500">
                    <ul className="pl-4 list-disc leading-7">
                      <li>Introduction to the employees in the workplace</li>
                      <li>Receipt of the sexual harassment complaint</li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between px-4 py-2 text-left text-lg font-bold uppercase text-e-blue focus:outline-none focus-visible:ring focus-visible:ring-e-blue focus-visible:ring-opacity-75 border-b border-gray-300 ">
                    <span>Initial Response </span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-e-blue`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-500">
                    <ul className="pl-4 list-disc leading-7">
                      <li>
                        triage issues to identify likely causes of the complaint
                        and alternative methods of resolution;
                      </li>
                      <li>
                        implement the right reasonable response action for
                        better outcomes with reference to applicable workplace
                        policies and procedures;
                      </li>
                      <li>understand the types of investigations;</li>
                      <li>
                        determine whether an investigation is necessary or not;
                      </li>
                      <li>
                        understand the implications of applicable enterprise
                        agreements, policies and contracts of employment;
                      </li>
                      <li>
                        identify an investigator within the organisation or
                        external;{" "}
                      </li>
                      <li>claim and maintain legal professional privilege </li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between px-4 py-2 text-left text-lg font-bold uppercase text-e-blue focus:outline-none focus-visible:ring focus-visible:ring-e-blue focus-visible:ring-opacity-75 border-b border-gray-300">
                    <span>Planning and preparing for the investigation</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-e-blue`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-500">
                    <ul className="pl-4 list-disc leading-7">
                      <li>create a clearly defined scope letter;</li>
                      <li>
                        frame allegations and provide meaningful particulars;
                      </li>
                      <li>communicate with relevant parties;</li>
                      <li>
                        understand the practical application of legal terms such
                        as standard of proof and natural justice/ procedural
                        fairness;
                      </li>
                      <li>
                        prepare an investigation plan including establishing
                        timeframes
                      </li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between px-4 py-2 text-left text-lg font-bold uppercase text-e-blue focus:outline-none focus-visible:ring focus-visible:ring-e-blue focus-visible:ring-opacity-75 border-b border-gray-300">
                    <span>The Investigation Process</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-e-blue`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-500">
                    <p className="font-bold">
                      Conducting an effective investigations process
                    </p>
                    <ul className="pl-4 list-disc leading-7">
                      <li>gather evidence;</li>
                      <li>decide who to interview and in what order;</li>
                      <li>set expectations of stakeholders;</li>
                      <li>keep accurate and confidential records;</li>
                      <li>determine whether to use experts or not;</li>
                      <li>protect confidentiality;</li>
                      <li>
                        develop investigative interview techniques and style;
                      </li>
                      <li>
                        troubleshoot common investigation issues and legal
                        risks;
                      </li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between px-4 py-2 text-left text-lg font-bold uppercase text-e-blue focus:outline-none focus-visible:ring focus-visible:ring-e-blue focus-visible:ring-opacity-75 border-b border-gray-300">
                    <span>Finalising the Investigation and next steps</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-e-blue`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-md text-gray-500">
                    <ul className="pl-4 list-disc leading-7">
                      <li>
                        review evidence including resolving issues such as
                        'credit worthiness' and drawing adverse inferences;
                      </li>
                      <li>make well-reasoned findings of fact;</li>
                      <li>compile a report</li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <div className="py-12">
              <p className="mt-3 max-w-3xl mx-auto text-lg text-gray-500">
                Throughout the workshop, participants will apply the case study
                to develop their own set of tailored example documents to take
                back to their workplace and apply, if necessary, at a later
                stage – as well as the presenter’s own best practice examples .
                These documents will be supplemented by current case law and
                actual examples from the presenter’s experience as a lawyer and
                HR Manager. In addition each participant will also get a
                workbook, presentation slides and notes to supplement their
                worked examples.
              </p>
            </div>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
            <div className="relative z-10">
              <div className="prose prose-indigo text-gray-500 mx-auto lg:max-w-none"></div>
            </div>
            <div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
              <svg
                className="hidden lg:block absolute top-0 right-0 -mt-96 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-[600px] xl:-mr-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
                />
              </svg>
            </div>
            {/* <svg width="400" height="651" viewBox="0 0 400 651" fill="none" xmlns="http://www.w3.org/2000/svg" className="block absolute top-[1750px] right-0 ">
<g opacity="0.6">
<path d="M321.999 0C244.73 0 173.924 27.538 118.29 73.5294V215.478C157.346 141.949 234.053 91.9828 321.999 91.9828C433.828 91.9828 527.393 172.61 548.466 279.639H168.023V371.622H548.466H629.95H640.627C642.875 356.575 643.999 341.245 643.999 325.63C643.999 145.923 499.576 0 321.999 0Z" fill="url(#paint0_linear_1937_2614)" fill-opacity="0.2"/>
<path d="M644 558.142L545.096 559.562C545.658 558.994 340.826 558.142 340.826 558.142C334.644 558.71 328.182 558.994 322 558.994C315.819 558.994 309.637 558.71 303.736 558.142H301.489V557.858C184.602 547.354 92.7225 448.558 91.0367 328.185H90.4747V295.537H90.1937V99.6479C34.2792 158.131 0 237.622 0 325.346C0 340.961 1.12391 356.007 3.09075 371.054L5.05759 383.829C32.3124 535.43 164.091 650.977 321.719 650.977C336.611 650.977 643.719 647.854 643.719 647.854V558.142H644Z" fill="url(#paint1_linear_1937_2614)" fill-opacity="0.2"/>
</g>
<defs>
<linearGradient id="paint0_linear_1937_2614" x1="162.748" y1="148.137" x2="707.058" y2="405.773" gradientUnits="userSpaceOnUse">
<stop stop-color="#1D71B8"/>
<stop offset="1" stop-color="#F4F6FC" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_1937_2614" x1="54.4616" y1="319.42" x2="762.484" y2="596.139" gradientUnits="userSpaceOnUse">
<stop stop-color="#1D71B8"/>
<stop offset="1" stop-color="#F4F6FC" stop-opacity="0"/>
</linearGradient>
</defs>
</svg> */}
          </div>
        </div>
      </div>

      {/* <div className="bg-gradient-to-br from-e-blue via-[#489be2] to-e-yellow">
      <div className="max-w-7xl text-center mx-auto py-12 px-4 sm:px-6 lg:py-12 lg:px-8 lg:flex lg:items-center">
        <div className="text-center mx-auto">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            Sign up for our 'Tips & Trends' Articles
          </h2>
          <p className="mt-3 max-w-3xl text-lg text-white">
          Throughout the workshop, participants will apply the case study to develop their own set of tailored example documents to take back to their workplace and apply, if necessary, at a later stage – as well as the presenter’s own best practice examples . These documents will be supplemented by current case law and actual examples from the presenter’s experience as a lawyer and HR Manager. In addition each participant will also get a workbook, presentation slides and notes to supplement their worked examples.
          </p>
        </div>

      </div>
    </div> */}
      <Newsletter />
    </Layout>
  );
};

export default WorkplaceInvestigations;
